import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { func } from '../../Utilities/logFunc'
import { getAllFreeAvatar, getCountryList, getStateList } from '../../Utilities/controller'
import { errors } from '../../Utilities/error'
import { routes } from '../../Utilities/routesFb'
import { useNavigate } from 'react-router'
import { url } from '../../Utilities/url'
import Loader from '../Common/Loader'
import { logout } from '../../Utilities/logout'
import { toast } from 'react-toastify'
import LoginLandingPage from '../LandingPage/LoginLandingPage'
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { async } from 'q'
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
import { requestUpdateProfile } from '../../Utilities/withJwtController'
import { singularSdk } from "singular-sdk";

const Avartar = () => {
  const navigate = useNavigate()
  const [avatar, setAvatar] = useState([])
  const [show, setShow] = useState(true);
  const [userName, setUserName] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [avatarId, setAvatarId] = useState('')
  const [avatarError, setAvatarError] = useState('')
  const [country, setCountry] = useState()
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [state, setState] = useState()
  const [index, setIndex] = useState()
  const [stateIndex, setStateIndex] = useState()
  const [loginType, setLoginType] = useState(false)
  // const [userCountryObj, setUserCountryObj] = useState(null);
  const { t } = useTranslation();
  const countyId = localStorage.getItem('country')
  const onChangeHandler = (e) => {
    setUserName(prev => e.target.value)
    setError('')
  }

  const handleClose = () => {
    setShow(false)
    logout()
  };
  const fetchAvatar = async () => {
    try {
      const res = await getAllFreeAvatar()
      func('avatar^^^^^^^^response', res)
      setAvatar(res?.data?.list)
      setLoading(false)

    }
    catch (error) {
      setLoading(false)
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        console.log("res^^^^^^^^^:Error", error)
      //logout()
      setError(error?.response?.data?.errors?.[0]?.msg)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg)
    }
  }
  const fetchCountry = async () => {
    try {
      const res = await getCountryList();
      if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      // let index = null;
      res?.list?.filter((item, ind) => {
        if (countyId === 'null') {
          if (item?.dialingCode === "+91") {
            // index = ind;
            // console.log(item);
            setCountry(item)
            // return index;
          }
        } else {
          // console.log('------',item?._id.toString())
          // console.log('------',countyId)
          if (item?._id.toString() === countyId) {
            setCountry(item)
          }
        }
      });
      // func("index", index);
      // setIndex(index)
      // setCountry(res?.list?.[index]?._id)
    } catch (error) {
      toast.error(error?.response?.errors[0]?.msg)
    }
    fetchState();
  }
  const fetchState = async () => {
    try {
      const res = await getStateList({
        skip: 0,
        limit: 100,
        sortBy: "name",
        sort: "asc",
      });
      // console.log(res);
      if (res.data.success) {
        setStateList(res.data.list)
        // setTimeout(() => {
        console.log(res.data.list[0]);
        setState(res.data.list[0])
        // }, 2000);
      }
      // if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      // let index = null;
      // res?.list?.filter((item, ind) => {
      //   if (item?.dialingCode == "+91") {
      //     index = ind;
      //     return index;
      //   }
      // });
      // func("index", index);
      // setIndex(index)
      // setCountry(res?.list?.[index]?._id)
    } catch (error) {
      toast.error(error?.response?.errors[0]?.msg)
    }
  }
  useEffect(() => {
    // if(JSON.stringify(localStorage.getItem('isDetailsFilled')) == true) navigate('/home')
    setLoading(true)
    fetchCountry()
    fetchAvatar()
    let loginTypeFetch = JSON?.parse(localStorage.getItem("loginInfo"))?.username
    if (isNumeric(loginTypeFetch)) {
      setLoginType(true)
    }
    else {
      setLoginType(false)
    }
  }, [])

  const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)

  const submitHandler = (e) => {
    e.preventDefault()
    if (userName == '') {
      setError(t("error_enter_gamerji_username"))
    }
    if (avatarId == '') {
      setAvatarError(t('error_Select_your_Gamerji_Avatar'))
    }
    if (userName !== '' && avatarId !== '') {
      callCreateProfile()
    }
  }

  const callCreateProfile = async () => {
    var payload = {
      gamerjiName: userName,
      isDetailsFilled: true,
      avatar: avatarId,
      // address: {
      //   country: country
      // }
    }
    if (countyId === 'null' || countyId === null) {
      // data.phoneCode = dialCode;
      if (country?.dialingCode === "+91" || country?.dialingCode === "91") {
        payload.address = { country: country._id, state: state._id };
      } else {
        payload.address = { country: country._id };
      }
      payload.isEmail = true;
    } else {
      payload.address = { country: countyId, state: state._id };
    }

    // func('==========', payload)
    setLoading(true)
    try {
      const res = await requestUpdateProfile(payload)
      console.log("callCreateProfile response:", res)
      if (res?.data?.success) {
        eventTracking(events.SIGNUP_COMPLATE, {
          GJ_GamerJiName: userName,
          GJ_AvatarID: avatarId,
          GJ_Country: payload.address != null ? payload.address.country : "",
          GJ_State: payload.address != null ? payload.address.state : "",
          GJ_UserID: res?.data?.user_id,

        });

        singularSdk.event("Create_Profile_btn",
          {
            GJ_GamerJiName: userName,
            GJ_AvatarID: avatarId,
            GJ_Country: payload.address != null ? payload.address.country : "",
            GJ_State: payload.address != null ? payload.address.state : "",
            GJ_UserID: res?.data?.user_id
          });
        localStorage.removeItem("isDetailsFilled")
        // localStorage.setItem('userData', JSON.stringify(res?.item))
        toast.success(res?.data?.msg)
        // navigate("/home")
        // window.location.href = '/'
        if (localStorage.getItem('code')) {
          window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;

        }

        else
          window.location.href = "/" + routes.home;
      }
      else {
        console.log(res?.response?.data?.errors?.[0]?.msg)
        setError(res?.response?.data?.errors?.[0]?.msg)

      }
    } catch (error) {
      func("error", error)
      // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      // logout()
      console.log(error)
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      setError(error?.response?.data?.errors?.[0]?.msg)
    }
    setLoading(false)
  }

  const customStyles = {

    // menu: (defaultStyles, state) => ({
    //   ...defaultStyles,
    //   // backgroundColor: "red",
    //   minHeight:'15rem',
    //   backgroundColor: "var(--secondary-color-emphasis)",
    // }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis) !important"
        : "var(--secondary-color-emphasis)",
      height: '2.5rem',
      "&:hover": {
        // color:'var(--dashboard-bg)',
        backgroundColor: 'var(--accent-color)'
      },
      padding: "0.65rem 1.25rem",
      borderRadius: '20px',


    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "0.5rem",

      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      caretColor: 'transparent',
      minHeight: '3.75rem',
      border: '1px solid var(--body-color)'

    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      width: "15rem",
      marginRight: "0.75rem",
      padding: "0.35rem",

    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "1.85rem",

    }),
    dropdownIndicator: (defaultStyles) => ({

      color: '#000',
      width: '1.5rem',
      height: '1.5rem',
      padding: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    })
  };


  const onClickHandler = (item) => {

    eventTracking(events.avatar_selected, { GJ_AvatarID: item })
    setAvatarId(item)
    setAvatarError('')
  }
  // console.log(isLoading);
  const customFilter = (option, searchText) => {
    // console.log(option, searchText)
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase())

    ) {
      return true;
    } else {
      return false;
    }
  }
  const stateCustomFilter = (option, searchText) => {
    // console.log(option, searchText)
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase())

    ) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <LoginLandingPage>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal avatarModal modal fade "
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body >
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div class="row justify-content-center py-4">
            <div class="col-12">
              <div class="tab-content auth-content text-center">
                <h1 class="h2  pb-5 text-dark">{t("login.welcome_to_gamerji")}</h1>
                <h5 class="mb-3 text-dark">
                  {t("login.identity")}
                </h5>
                <div class="row justify-content-center mb-5 text-dark d-flex">
                  <div class="col-lg-5 col-sm-6">
                    <input
                      type="text"
                      class="form-control text-black"
                      id="inputCouponCode"
                      placeholder={t("login.gamerji_name")}
                      value={userName}
                      maxLength={16}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    {
                      error && <p className='error'>{error}</p>
                    }
                  </div>
                  {
                    (countyId === 'null' || countyId == null && !loginType) &&
                    <div className="d-flex justify-content-center avatar-country" style={{ zIndex: '999' }}>
                      <label
                        for="inputCoutryCode"
                        className="form-label text-nowrap"
                      >
                        {t("select_country")}
                      </label>
                      <Select
                        className='col-lg-5 col-sm-6 text-black'
                        styles={customStyles}
                        name='countryOption'
                        // value={countryCode}
                        defaultValue={countryList[index]}
                        options={countryList}
                        onChange={(e) => { setCountry(e) }}
                        isSearchable={true}
                        filterOption={customFilter}
                        getOptionLabel={ele => (
                          <div className="d11-menu text-black" style={{ display: 'flex', alignItems: 'center' }}>
                            <span class="icon" >
                              <img class="icon-16 me-1 d11-country-flag"

                                src={url.imageUrl + ele?.flag?.default} alt="" />
                            </span>{"  "}<span >{ele?.name}</span>
                          </div>
                        )}
                      />
                    </div>

                  }
                  {/* {console.log("loginType", !loginType)} */}
                  {
                    stateList.length > 0 && !loginType &&
                    (country?.dialingCode === "+91" || country?.dialingCode === "91") &&
                    <div className="d-flex justify-content-center avatar-country" style={{ zIndex: '99' }}>
                      <label
                        for="inputCoutryCode"
                        className="form-label text-nowrap"
                      >
                        {t("Profile.select_state")}
                      </label>
                      <Select
                        className='col-lg-5 col-sm-6'
                        styles={customStyles}
                        name='stateOption'
                        // value={countryCode}
                        defaultValue={stateList[0]}
                        options={stateList}
                        onChange={(e) => setState(e)}
                        getOptionLabel={ele => (
                          <div className="d11-menu text-black" style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <span class="icon" >
                                <img  class="icon-16 me-1 d11-country-flag"
                                
                                src={url.imageUrl + ele?.flag?.default} alt="" />
                              </span>{"  "} */}
                            <span >{ele?.name}</span>
                          </div>
                        )}
                        isSearchable={true}
                        filterOption={stateCustomFilter}
                      />
                    </div>
                  }
                </div>
                <h4 class="mb-3 text-dark">
                  {t("login.now_choose_cool_avatar_to_go_with_your_profile")}
                </h4>
                <h4 class="mb-3 text-dark">
                  {t("login.you_can_always_change_this_or_unlock_new_ones")}
                </h4>
                <div class="row justify-content-center ">
                  <div class="col-lg-6 col-md-8 px-lg-0">
                    <div class="radioBlock">
                      <div class="radioInner">
                        {
                          avatar?.map((item, index) => (
                            item?.isBuy ?
                              <div class="form-check" key={index} >
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={`radio${index}`}
                                  checked={item?._id == avatarId}
                                  onChange={(e) => onClickHandler(item?._id)}
                                />
                                <label class="form-check-label" for={`radio${index}`}>
                                  <img src={url.imageUrl + item?.img?.default} alt="" style={{ width: '60px', height: '120px' }} />
                                  <span class="d-block mt-2">{item?.name}</span>
                                </label>
                              </div>
                              : ""
                          ))
                        }
                      </div>
                      {
                        avatarError && <p className='error'>{avatarError}</p>
                      }
                    </div>
                  </div>
                </div>
                {/* {console.log(countyId)} */}


                <div class="row justify-content-center mt-5">
                  <div class="col-lg-5 mt-0 col-sm-6">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary btn-arrow" onClick={(e) => submitHandler(e)}>
                        {t("login.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      {isLoading ? <Loader /> : <></>}
    </LoginLandingPage>

  )
}

export default Avartar