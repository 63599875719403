import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/newStyle.css";

import LandingPage from "./Components/LandingPage/LandingPage";

import Login from "./Components/Auth/Login";
import EmailLogin from "./Components/Auth/EmailLogin";
import Otp from "./Components/Auth/Otp";
import EmailOtp from "./Components/Auth/EmailOtp";
import EmailLoginOtp from "./Components/Auth/EmailLoginOtp";
import Avartar from "./Components/Auth/Avartar";
import { Navigate, Route, Routes } from "react-router";

import { routes } from "./Utilities/routesFb";
import HomePage from "./Components/HomePage/HomePage";
import GameTournament from "./Components/HomePage/Tournament/GameTournament";
import Main from "./Components/HomePage/Main";
import TournamentDetails from "./Components/HomePage/Tournament/TournamentDetails";
import { useEffect, useState } from "react";
import { func } from "./Utilities/logFunc";

import WorldOfEsports from "./Components/HomePage/WorldOfEsports/WorldOfEsports";
import WOEMain from "./Components/HomePage/WorldOfEsports/WOEMain";
import News from "./Components/HomePage/WorldOfEsports/AllNews";
import NewsDetails from "./Components/HomePage/WorldOfEsports/NewsDetails";
import AllPopularVideos from "./Components/HomePage/WorldOfEsports/AllPopularVideos";
import AllTopProfiles from "./Components/HomePage/WorldOfEsports/AllTopProfiles";
import LeaderBoard from "./Components/HomePage/LeaderBoard/LeaderBoard";
import PrivateContest from "./Components/HomePage/PrivateContest/PrivateContest";
import ManagePrivateContest from "./Components/HomePage/PrivateContest/ManagePrivateContest";
import MyContest from "./Components/HomePage/MyContest/MyContest";
import Contest from "./Components/HomePage/Contest/Contest";
import ContestDetails from "./Components/HomePage/Contest/ContestDetails";
import SignupOtp from "./Components/Auth/SignupOtp";
import Base from "./Components/HomePage/PrivateContest/Base";
import CreatePrivateContest from "./Components/HomePage/PrivateContest/CreatePrivateContest";
import Protected from "./Protected";
import CustomerCare from "./Components/HomePage/CustomerCare/CustomerCare";
import CustomerCareForm from "./Components/HomePage/CustomerCare/CustomerCareForm";
import CRMMain from "./Components/HomePage/CustomerCare/CRMMain";
import RewardStore from "./Components/HomePage/RewardStore/RewardStore";
import ScrimDetails from "./Components/HomePage/PrivateContest/ScrimDetails";
import FreeGames from "./Components/HomePage/FreeGames/FreeGames";

import Legality from "./Components/HomePage/Legality/Legality";
import CoinStore from "./Components/HomePage/CoinStore/CoinStore";
import MyProfile from "./Components/Profile/MyProfile";
import PaymentStatus from "./Components/HomePage/CoinStore/PaymentStatus";
import TicketDetails from "./Components/HomePage/CustomerCare/TicketDetails";
import SubscriptionTypeList from "./Components/Subscription/SubscriptionTypeList";
import PaymentStatusSubscription from "./Components/Subscription/PaymentStatusSubscription";
import UserProfile from "./Components/Profile/UserProfile";
import Contact from "./Components/LandingPage/Contact";
import About from "./Components/LandingPage/About";
import LandingMain from "./Components/LandingPage/LandingMain";
import TermsAndCondition from "./Components/LandingPage/TermsAndCondition";
import Policy from "./Components/LandingPage/Policy";
import PayuPaymentStatus from "./Components/HomePage/CoinStore/PayuPaymentStatus";
// import { singularSdk, SingularConfig } from "singular-sdk";
import { initSingular } from "./Utilities/singularConfig"; 
import axios from "axios";
import { countryCodeLang, countryCodeLangName } from "./Utilities/countryCodeLang";
import i18next from "i18next";
import Loader from "./Components/Common/Loader";

function App() {
  const [gameName, setGameName] = useState("");
  const [gameId, setGameId] = useState("");
  const [blog, setBlog] = useState(null);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false)
  // console.log("****",window.location.pathname)
  const isDetailsFilled = localStorage.getItem("isDetailsFilled");
// console.log(isDetailsFilled)
const urlParams = new URLSearchParams(window.location.search);
const codeFromURL = urlParams.get('code');
const lang = urlParams.get('lang')
// console.log(lang)
const getGeoInfo = async () => {
  // console.log("called")
  setLoading(true)
  let data = {};
  await axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      data = response.data;
      // console.log(
      //       // data.ip,
      //       // data.country_name,
      //     data.country_calling_code,
      //     countryCodeLang.hasOwnProperty(data.country_calling_code),
      //     countryCodeLangName[data.country_calling_code]
      //     //  data.city,
      //     // data.timezone
      //   )
      // setCountryCode(data?.country_calling_code);
      localStorage.setItem("countryCode",data?.country_calling_code)
      let langCode = countryCodeLang[data.country_calling_code]
      console.log(langCode,countryCodeLangName[langCode])
      if(countryCodeLang.hasOwnProperty(data.country_calling_code)){
        i18next.changeLanguage(countryCodeLang[data.country_calling_code]);
        localStorage.setItem('GJLang', countryCodeLangName[langCode])
        localStorage.setItem('GJLangCode', countryCodeLang[data.country_calling_code])
      }
      else{
        i18next.changeLanguage('en');
        localStorage.setItem("GJLang", 'English');
        localStorage.setItem(
          "GJLangCode",
          'en'
        );
      }
      setLoading(false)
    })

    .catch((error) => {
      setLoading(false)
      console.log(error);
    });
  return data;
};

 
  if(codeFromURL)
    localStorage.setItem('code', codeFromURL)
  
  const handleClickGame = (name, id = "") => {
    setGameName(name);
    setGameId(id);
  };

  const handleClickBlog = (item) => {
    setBlog(item);
  };

  // function initSingular() {
  //   const config = new SingularConfig("gamerji_e4acabae", "f9208e300d9309cde0c0b9140752faf7", "portal.gamerji.com")
  //     .withInitFinishedCallback(initParams => {
  //       // This callback will be called when the init method has finished
  //       // You can get Singular Device id here
  //       const singularDeviceId = initParams.singularDeviceId;

  //       console.log("singularDeviceId", singularDeviceId);

  //     });
  //   singularSdk.init(config);
  // }
  const findIP = ()=>{
    if (lang){

      if(countryCodeLangName.hasOwnProperty(lang)){
        console.log(urlParams.get('lang'))
        console.log(countryCodeLangName[lang], lang)
        i18next.changeLanguage(lang);
        localStorage.setItem("GJLang", countryCodeLangName[lang]);
        localStorage.setItem(
          "GJLangCode",
          lang
        );
      }
      else{
        i18next.changeLanguage('en');
        localStorage.setItem("GJLang", 'English');
        localStorage.setItem(
          "GJLangCode",
          'en'
        );
      }
      }
      else{
        console.log(localStorage.getItem('GJLangCode'), localStorage.getItem('GJLangCode') != undefined , localStorage.getItem('GJLang') != "undefined")
        if (! (localStorage.getItem('GJLangCode') != "undefined" && localStorage.getItem('GJLang') != "undefined"))
        getGeoInfo()
      }
  }
  useEffect(() => {
    initSingular()
    findIP()
  }, []);
  if (loading) <Loader />
  return (
    <>
    {/* {console.log("", isDetailsFilled === "false", token)} */}
      <Routes>
        {/* <Route
         
          path={routes.home}
          element={
            token ? (
              isDetailsFilled === "false" ? (
                <Navigate replace to="/avatar" />
              ) : (
                <Navigate replace to="/home" />
              )
            ) : (
              <Navigate replace to="/login" />
            )
          }
        /> */}
        <Route
          exact
          path={routes.login}
          element={token ? isDetailsFilled === "false" ? (
            <Navigate replace to="/avatar" />
          ) : (
            <Navigate replace to="/home" />
          )  : <Login />}
        />
        <Route
          exact
          path={routes.emailLogin}
          element={token ? isDetailsFilled === "false" ? (
            <Navigate replace to="/avatar" />
          ) :<Navigate replace to="/home" /> : <EmailLogin />}
        />
         <Route
          exact
          path={routes.emailSignup}
          element={token ? isDetailsFilled === "false" ? (
            <Navigate replace to="/avatar" />
          ) :  <Navigate replace to="/home" /> : <EmailLogin />}
        />
        <Route exact path={routes.login} element={<Login />} />
        <Route exact path={routes.otp} element={token ? <Navigate replace to="/home" /> :<Otp />} />
        <Route exact path={routes.emailotp} element={token ? <Navigate replace to="/home" /> :<EmailOtp />} />
        <Route exact path={routes.emailloginotp} element={token ? <Navigate replace to="/home" /> :<EmailLoginOtp />} />
        <Route exact path={routes.signupotp} element={token ? <Navigate replace to="/home" /> :<SignupOtp />} />
        <Route
          exact
          path={routes.avatar}
          element={<Protected Component={Avartar} />}
        />

        <Route
          exact
          path={routes.home}
          element={
            token ? (
              isDetailsFilled === "false" ? (
                <Navigate replace to="/avatar" />
              ) : (
                <HomePage />
              )
            ) : (
              <Navigate replace to="/login" />
            )
          }
        >
          <Route path="" element={<Protected Component={Main} />} />
          <Route path={routes.gameTournament} element={<Protected Component={GameTournament} />} />
          <Route
            path={routes.tournamentDetails}
            element={<Protected Component={TournamentDetails} />}
          />

          <Route exact path={routes.worldOfEsports} element={<Protected Component={WOEMain} />}>
            <Route
              exact
              path=""
              element={<WorldOfEsports handleClickBlog={handleClickBlog} />}
            />

            <Route
              exact
              path={routes.news}
              element={<News handleClickBlog={handleClickBlog} />}
            />
            <Route
              exact
              path={routes.newsDetails}
              element={<NewsDetails blog={blog} />}
            />
            <Route
              exact
              path={routes.popularVideos}
              element={<AllPopularVideos />}
            />
            <Route
              exact
              path={routes.topProfiles}
              element={<AllTopProfiles />}
            />
          </Route>

          <Route exact path={routes.leaderBoard} element={<LeaderBoard />} />

          <Route exact path={routes.privateContest} element={<Base />}>
            <Route exact path="" element={<PrivateContest />} />
            <Route
              exact
              path={routes.managePrivateContest}
              element={<ManagePrivateContest />}
            />
            <Route
              exact
              path={routes.createPrivateContest}
              element={<CreatePrivateContest />}
            />
            <Route
              exact
              path={routes.editPrivateContest}
              element={<CreatePrivateContest />}
            />
          </Route>

          <Route exact path={routes.myContest} element={<MyContest />} />
          <Route exact path={routes.scrimDetails} element={<ScrimDetails />} />
          <Route exact path={routes.freeGames} element={<FreeGames />} />

          <Route exact path={routes.gameContest} element={<Contest />} />
          <Route
            exact
            path={routes.contestDetails}
            element={<ContestDetails />}
          />
          <Route exact path={routes.customerCare} element={<CRMMain />}>
            <Route exact path="" element={<CustomerCare />} />

            <Route
              exact
              path={routes.raiseACompaint}
              element={<CustomerCareForm />}
            />
            <Route exact path={routes.ticketDetails} element={<TicketDetails />} />

          </Route>
          <Route exact path={routes.rewardStore} element={<RewardStore />} />
          <Route exact path={routes.legality} element={<Legality />} />
          <Route exact path={routes.coinStore} element={<CoinStore />} />
          <Route exact path={routes.myProfile} element={<MyProfile />} />
          <Route exact path={routes.userProfile} element={<UserProfile />} />

          <Route exact path={routes.paymentStatus} element={<PaymentStatus />} />
          <Route exact path={routes.getSubscriptionTypeList} element={<SubscriptionTypeList />} />
          <Route exact path={routes.paymentStatusSubscription} element={<PaymentStatusSubscription />} />
          <Route exact path={routes.payuPaymentStatus} element={<PayuPaymentStatus />} />



        </Route>

        {/* <Route exact path="/" 
          // element={<LandingMain />} 
          element={token ? <Navigate replace to="/home" /> : <LandingMain />}
        /> */}
        <Route exact path="/" 
          element={token ? <Navigate replace to="/home" /> : <Navigate replace to="/login" /> }
        />
        <Route exact path={routes.contact} element={<Contact />} />
        <Route exact path={routes.about} element={<About />} />
        <Route exact path={routes.termsAndCondition} element={<TermsAndCondition />} />
        <Route exact path={routes.policy} element={<Policy />} />



      </Routes>
    </>
  );
}

export default App;
